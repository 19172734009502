
import { getTagList } from '@/api';
import { ref } from 'vue';
import { useRouter } from 'vue-router'
import store from "@/store";
export default {
    setup(){
        const tagList = ref();
        const router = useRouter();
        store.dispatch('isLoading');
        getTagList().then((res: any) => {
            tagList.value = res.data;
            store.dispatch('notLoading');
        });
        const handleCardClick = (id,articleCount) => {
            if(articleCount > 0)
                router.push('/tags/' + id);
        }
        return {
            tagList,
            handleCardClick,
        };
    }

}
